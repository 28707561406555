import { Separator } from '@/components/ui/separator';
import ProfileSideNav from '@/screens/profile/profile-nav';
import React from 'react';
import { Outlet } from 'react-router-dom';

const sidebarNavItems = [
    {
      title: "Profile",
      href: "/v1/profile",
    },
    {
      title: "Billing",
      href:  "/v1/profile/billing",
    },
]

const ProfileLayout = () => {
    return (
        <div className="space-y-6 p-10 pb-16 block">
            <div className="space-y-0.5">
                <h2 className="text-2xl font-bold tracking-tight">Settings</h2>
                <p className="text-muted-foreground">
                    Manage your account settings and preferences.
                </p>
            </div>
            <Separator className="my-6" />
            <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
                <aside className="-mx-4 lg:w-1/5">
                    <ProfileSideNav items={sidebarNavItems} />
                </aside>
                <div className="flex-1">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default ProfileLayout;
import { ImageType } from "@/lib/upload";
import { getApps, initializeApp } from "firebase/app";
import { ReCaptchaV3Provider, initializeAppCheck } from "firebase/app-check";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAQwOJ3XerHDPqKzKroiL-bwxGaghv0TRk",
    authDomain: "report-ai-app.firebaseapp.com",
    projectId: "report-ai-app",
    storageBucket: "report-ai-app.appspot.com",
    messagingSenderId: "519379622427",
    appId: "1:519379622427:web:fe45f1fd9ac2965331b6be",
    measurementId: "G-LNJ0R9LNNP"
};

// Initialize Firebase
const apps = getApps();
const app = apps.length > 0 ? apps[0] : initializeApp(firebaseConfig);

if (process.env.NODE_ENV !== 'production') {
    Object.assign(window, {
        FIREBASE_APPCHECK_DEBUG_TOKEN:
            process.env.REACT_APP_CHECK_DEBUG_TOKEN,
    });
}

const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
        process.env.REACT_APP_RECAPTCHA_SITE_KEY as string
    ),
    isTokenAutoRefreshEnabled: true,
});

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const formatAuthError = (err: string) => {
    console.log(err)
    let formatted = "An error occurred, please try again.";
    switch (err) {
        case "Firebase: Error (auth/email-already-in-use).":
            formatted = "Email in use. Please login instead.";
            break;
        case "Firebase: Error (auth/email-already-exists).":
            formatted = "Email in use. Please login instead.";
            break;
        case "Firebase: Error (auth/invalid-email).":
            formatted = "Invalid email address.";
            break;
        case "Firebase: Error (auth/invalid-credential).":
            formatted = "Unable to find your account.";
            break;
        case "Firebase: Error (auth/too-many-requests).":
            formatted = "Check your inbox to verify your email first."
            break;
        default:
            formatted = "An error occurred, please try again."
            break;
    }

    return formatted;
}

export {
    app,
    db,
    auth,
    appCheck,
    storage,
    formatAuthError
} 
import { Badge } from '@/components/ui/badge';
import { Button, buttonVariants } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { db, storage } from '@/lib/firebase';
import { cn, reportUrl } from '@/lib/utils';
import { useAuth } from '@/providers/auth-provider';
import { useSiteState } from '@/providers/state-provider';
import { reportTypes } from '@/screens/reports/data/data';
import { ReportType } from '@/screens/reports/data/schema';
import { DocumentData, collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { Download } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const RecentReports = () => {

    const { user } = useAuth();
    const { setRecentReports } = useSiteState();

    const [reports, setReports] = useState<DocumentData[]>([]);

    const downloadReport = async(path: string) => {
        const url = await getDownloadURL(ref(storage, path));
        window.open(url);
    }

    useEffect(() => {
        if (user?.uid) {
            const q = query(
                collection(db, "Reports"), 
                where("userId", "==", user.uid), 
                orderBy("created_date", "desc"),
                limit(10)
            );
            const unsubscribe = onSnapshot(q, (docs) => {
                const r = [] as ReportType[];
                docs.forEach(d => r.push(d.data() as ReportType));
                setReports(r);
                setRecentReports(r.filter((r) => !r.pdf));
            });

            return unsubscribe;
        }
    }, [user])

    return (
        <Card>
            <CardHeader>
                <CardTitle>Recent Reports</CardTitle>
                <CardDescription>
                    Most recent reports generated using Reportable.
                </CardDescription>
            </CardHeader>
            <CardContent className='p-2 md:p-6 pt-0'>
                <Table>
                    {reports.length === 0 && (
                        <TableCaption>No reports generated.</TableCaption>
                    )}
                    <TableHeader>
                        <TableRow>
                            <TableHead>Job #</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Report Type</TableHead>
                            <TableHead className="text-right">Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {reports.map((report) => (
                        <TableRow key={`recent_${report.id}`}>
                            <TableCell className="font-medium max-w-[300px] line-clamp-1 text-ellipsis">{report.job_number}</TableCell>
                            <TableCell className="font-medium">
                                {report.status?.toLowerCase() === "draft" ? (
                                    <Badge variant="default" className="bg-amber-500">{report.status}</Badge>
                                ) : (
                                    <Badge variant="default" className="bg-emerald-600 capitalize">{report.status}</Badge>
                                )}
                            </TableCell>
                            <TableCell>{reportTypes.find((type) => type.value === report.report_type)?.label || 'Makesafe Report'}</TableCell>
                            <TableCell className="flex space-x-2 justify-end">
                                {report.pdf && (
                                    <Button type="button" className='px-3 py-1' variant="outline" onClick={() => downloadReport(report.pdf)}>
                                        <Download className='w-4 h-4' />
                                    </Button>
                                )}
                                <Link to={reportUrl(report?.reportTemplate, report.id)} className={cn(buttonVariants({ variant: "outline" }), "px-3 py-1")}>
                                    Edit
                                </Link>
                            </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

export default RecentReports;
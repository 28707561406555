"use client"

import { Cross2Icon } from "@radix-ui/react-icons"
import { Table } from "@tanstack/react-table"

import { Button, buttonVariants } from "@/components/ui/button"
import { Input } from "@/components/ui/input"

import { statuses } from "./data/data"
import { DataTableFacetedFilter } from "./data-table-faceted-filter"
import { useNavigate } from "react-router-dom"
import { PlusIcon } from "lucide-react"
import { Timestamp, collection, doc, increment, setDoc, updateDoc } from "firebase/firestore"
import { db } from "@/lib/firebase"
import { useCallback, useState } from "react"
import { Icons } from "@/components/icons"
import { useAuth } from "@/providers/auth-provider"
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"

interface DataTableToolbarProps<TData> {
  table: Table<TData>
}

interface ReportTemplateType {
  url: string;
  value: string;
  label: string;
}

const templates: ReportTemplateType[] = [
  {
    value: "assessment",
    label: "Assessment Report",
    url: "reports"
  },
  {
    value: "makesafe",
    label: "Make Safe Report",
    url: "reports/makesafe"
  }
]

export function DataTableToolbar<TData>({
  table,
}: DataTableToolbarProps<TData>) {

  const navigate = useNavigate();
  const { user } = useAuth();

  const isFiltered = table.getState().columnFilters.length > 0;

  const [loading, setLoading] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [reportTemplate, setReportTemplate] = useState<ReportTemplateType | null>(null);

  const newReport = useCallback(async() => {
    if (!user?.uid) return;

    setLoading(true);
    const d = doc(collection(db, "Reports"));

    await updateDoc(doc(db, "Users", user.uid), {
      credits: increment(-1)
    });
    await setDoc(d, {
      id: d.id,
      status: "Draft",
      userId: user?.uid,
      reportTemplate: reportTemplate?.value,
      created_date: Timestamp.fromDate(new Date())
    });
    setLoading(false);

    navigate(`/v1/${reportTemplate?.url}/${d.id}/client`);
  }, [user, reportTemplate]);

  return (
    <>
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          placeholder="Filter tasks..."
          value={(table.getColumn("type")?.getFilterValue() as string) ?? ""}
          onChange={(event) =>
            table.getColumn("type")?.setFilterValue(event.target.value)
          }
          className="h-8 w-[150px] lg:w-[250px]"
        />
        {table.getColumn("status") && (
          <DataTableFacetedFilter
            column={table.getColumn("status")}
            title="Status"
            options={statuses}
          />
        )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      {/* <DataTableViewOptions table={table} /> */}
      <Button
        onClick={() => setShowConfirm(true)}
        className={buttonVariants({ variant: "default", size: "sm" })}
        disabled={loading}
      >
        {loading ? (
          <Icons.spinner className="animate-spin"/>
        ) : (
          <>          
            <PlusIcon className="w-5 mr-2" />
            New Report
          </>
        )}
      </Button>
    </div>
    <AlertDialog open={showConfirm} onOpenChange={setShowConfirm}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Ready to start a new report?</AlertDialogTitle>
          <AlertDialogDescription>
            This will create a new report and use <b>1 Credit</b>. You can download the report, once complete, at any time.
            <div className="my-6">
              <p>Which type of report template would you like to create?</p>
              <Select
                value={reportTemplate?.value}
                onValueChange={e => setReportTemplate(templates.find(t => t?.value === e) || null)}
              >
                <SelectTrigger className="h-8 mt-1">
                  <SelectValue placeholder="Select Report Template" />
                </SelectTrigger>
                <SelectContent side="top">
                  {templates.map((r) => (
                    <SelectItem key={`rt_${r.value}`} value={r.value}>
                      {r.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={newReport} disabled={!reportTemplate}>Continue</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
    </>
  )
}
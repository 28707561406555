import { Link, Outlet } from 'react-router-dom';
import { SidebarNav } from '@/components/layout/sidebar';
import { TooltipProvider } from '@/components/ui/tooltip';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable';
import { cn, reportUrl } from '@/lib/utils';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Separator } from '@/components/ui/separator';

import logo from '@/assets/images/logo-light.png'
import { Button } from '@/components/ui/button';
import { signOut } from 'firebase/auth';
import { auth } from '@/lib/firebase';
import { SiteHeader } from '@/components/layout/site-header';
import { useSiteState } from '@/providers/state-provider';
import CreditModal from '@/components/credit-modal';

const Layout = () => {

    const [cookies] = useCookies(['react-resizable-panels:collapsed']);
    const defaultCollapsed = cookies['react-resizable-panels:collapsed'] ? cookies['react-resizable-panels:collapsed'] : undefined;

    const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

    return (
        <>
            <div className="relative">
                <section>
                    <div className="overflow-hidden min-h-screen bg-background shadow-md md:shadow-xl">
                        <div className="flex-col flex">
                            <TooltipProvider delayDuration={0}>
                                <ResizablePanelGroup
                                    direction="horizontal"
                                    onLayout={(sizes: number[]) => {
                                        document.cookie = `react-resizable-panels:layout=${JSON.stringify(sizes)}`
                                    }}
                                    className="h-full min-h-screen items-stretch"
                                >
                                    <ResizablePanel
                                        defaultSize={265}
                                        collapsedSize={4}
                                        collapsible={true}
                                        minSize={15}
                                        maxSize={20}
                                        className={cn("relative hidden md:block bg-secondary-dark", isCollapsed && "min-w-[50px] transition-all duration-300 ease-in-out")}
                                        onResize={(size, prevSize) => {
                                            const nowCollapsed = size === 4;
                                            setIsCollapsed(nowCollapsed);
                                            document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(nowCollapsed)}`
                                        }}
                                    >
                                        <SidebarInner isCollapsed={isCollapsed} />
                                    </ResizablePanel>
                                    <ResizableHandle withHandle />
                                    <ResizablePanel defaultSize={800} minSize={30}>
                                        <SiteHeader />
                                        <Outlet />
                                    </ResizablePanel>
                                </ResizablePanelGroup>
                            </TooltipProvider>
                        </div>
                    </div>
                </section>
            </div>
            <CreditModal />
        </>
    );
};

export const SidebarInner = ({ isCollapsed }: { isCollapsed?: boolean }) => {

    const { recentReports } = useSiteState();

    return <>
        <div className={cn("flex h-[52px] items-center py-8", isCollapsed ? 'h-[52px] px-1': 'px-2')}>
            <img src={logo} alt="Reportable logo" width={150} />
        </div>

        <Separator className='bg-border/50' />

        <SidebarNav isCollapsed={isCollapsed || false}/>

        <Separator className='bg-border/50' />

        {!isCollapsed && 
        <>
        <div className={cn("flex h-[42px] items-center", isCollapsed ? 'h-[42px] px-1': 'px-6')}>
            <span className='text-xs text-accent/60 uppercase'>Recent Reports</span>
        </div>
        {recentReports.map((report) => {
            return <Link key={`sidebar_rr_${report.id}`} className='flex space-x-2 px-6 py-3' to={reportUrl(report?.reportTemplate, report.id)}>
                <span className='text-white/80 hover:text-white capitalize line-clamp-1 text-ellipsis'>
                    {report.status}
                    {report.job_number ? ` - ${report.job_number}` : ''} 
                    {report.site_address1 ? ` - ${report.site_address1}` : ''} 
                </span>
            </Link>
        })}
        </>
        }

        {/* Reports go here */}

        <div className='absolute bottom-0 left-0 right-0 h-[56px]'>
            <Separator className='bg-border/50'/>
            <Button 
                type="button" 
                onClick={() => signOut(auth)}
                className="w-full h-full py-0 border-0 bg-secondary-dark/80 hover:bg-secondary-dark/80 hover:text-accent/80 text-accent"
            >Logout</Button>
        </div>
    </>
}

export default Layout;
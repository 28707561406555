import { db } from '@/lib/firebase';
import { useAuth } from '@/providers/auth-provider';
import { DocumentSnapshot, collection, getDocs, limit, orderBy, query, startAfter, endBefore } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { Pagination, PaginationContent, PaginationItem, PaginationNext, PaginationPrevious } from '@/components/ui/pagination';
import { Icons } from '@/components/icons';

interface Transaction {
    id: string;
    created_date: number;
    status: string;
    amount: number;
    credits: number;
}

type PageDirection = null | "prev" | "next";

const TransactionTable = () => {

    const { user } = useAuth();

    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchFrom, setStartFrom] = useState<DocumentSnapshot | null>(null);
    const [transactions, setTransactions] = useState<Transaction[]>([]);

    const paginate = useCallback(async(dir: PageDirection) => {
        if (user?.uid) {
            let q = query(
                collection(db, "Users", user.uid, "Transactions"),
                orderBy("created_date", "desc"),
                limit(10)
            );
            if (searchFrom && dir) {
                const startQuery = dir === "next" 
                    ? startAfter(searchFrom) 
                    : endBefore(searchFrom);
                q = query(q, startQuery);
            }
            const { docs } = await getDocs(q);
            if (docs.length > 0) {
                const txs = docs.map(d => d.data()).map(d => {
                    return {
                        id: d.id,
                        created_date: d.created_date,
                        status: d.status,
                        amount: d.amount,
                        credits: d.credits
                    }
                }) as Transaction[];
                setTransactions(txs);
                setStartFrom(docs[docs.length-1]);
            } else {
                setTransactions([]);
            }
            setLoading(false);
        }
    }, [user]);

    useEffect(() => {
        if (user) 
            paginate(null);
    }, [user])

    return (
       <Card>
            <CardHeader>
                <CardTitle>Credit Purchases</CardTitle>
                <CardDescription>
                    Your recent credit purchases on Reportable.
                </CardDescription>
            </CardHeader>
            <CardContent>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Payment ID</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Credits</TableHead>
                            <TableHead>Amount</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {transactions.length === 0 && !loading && (
                            <TableRow>
                                <TableCell colSpan={4} className='text-center'>
                                    No transactions found.
                                </TableCell>
                            </TableRow>
                        )}
                        {transactions.map((tx) => (
                            <TableRow key={tx.id}>
                                <TableCell className="font-medium">{tx.id.slice(0, 15)}...</TableCell>
                                <TableCell>{tx.status}</TableCell>
                                <TableCell>{tx.credits}</TableCell>
                                <TableCell>${Number(tx.amount).toFixed(2)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={4}>
                                <Pagination>
                                    <PaginationContent>
                                        {page > 1 && (
                                            <PaginationItem>
                                                <PaginationPrevious onClick={() => paginate("prev")} aria-disabled={page < 2} />
                                            </PaginationItem>
                                        )}
                                        <PaginationItem>
                                            {loading ? (
                                                <Icons.spinner className='animate-spin w-5 h-5' />
                                            ) : (!loading && transactions.length % 10 === 0) ? (
                                                <PaginationNext onClick={() => paginate("next")} />
                                            ) : null}
                                        </PaginationItem>
                                    </PaginationContent>
                                </Pagination>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </CardContent>
        </Card>
    );
};

export default TransactionTable;
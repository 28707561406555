"use client"

import { ColumnDef } from "@tanstack/react-table"
import { ReportType } from "./data/schema"
import { DataTableColumnHeader } from "./data-table-column-header"
import { DataTableRowActions } from "./data-table-row-actions"
import { Badge } from "@/components/ui/badge"
import { reportTypes, statuses } from "@/screens/reports/data/data"
import { Checkbox } from "@/components/ui/checkbox"
import { Link } from "react-router-dom"
import { Button, buttonVariants } from "@/components/ui/button"
import { buildReport } from "@/lib/reports"

export const columns: ColumnDef<ReportType>[] = [
  // {
  //   id: "select",
  //   header: ({ table }) => (
  //     <Checkbox
  //       checked={
  //         table.getIsAllPageRowsSelected() ||
  //         (table.getIsSomePageRowsSelected() && "indeterminate")
  //       }
  //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
  //       aria-label="Select all"
  //       className="translate-y-[2px]"
  //     />
  //   ),
  //   cell: ({ row }) => (
  //     <Checkbox
  //       checked={row.getIsSelected()}
  //       onCheckedChange={(value) => row.toggleSelected(!!value)}
  //       aria-label="Select row"
  //       className="translate-y-[2px]"
  //     />
  //   ),
  //   enableSorting: false,
  //   enableHiding: false,
  // },
  {
    accessorKey: "id",
    header: ({ column }) => (
      <div className="w-[0px]"/>
    ),
    cell: ({ row }) => <div className="w-[0px]"></div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "pdf",
    header: ({ column }) => (
      <div className="w-[0px]"/>
    ),
    cell: ({ row }) => <div className="w-[0px]"></div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "reportTemplate",
    header: ({ column }) => (
      <div className="w-[0px]"/>
    ),
    cell: ({ row }) => <div className="w-[0px]"></div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "autocomplete_address",
    header: ({ column }) => (
      <div className="w-[0px]"/>
    ),
    cell: ({ row }) => <div className="w-[0px]"></div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "job_number",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Job Number" />
    ),
    cell: ({ row }) => <div className="w-[120px]">{row.getValue("job_number")}</div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "site_address1",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Address" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium line-clamp-1 text-ellipsis">
            {row.getValue("autocomplete_address") || row.getValue("site_address1")}
          </span>
        </div>
      )
    },
  },
  {
    accessorKey: "inspection_date",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Inspection Date" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue("inspection_date")}
          </span>
        </div>
      )
    },
  },
  {
    accessorKey: "type",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Type" />
    ),
    cell: ({ row }) => {
      const label = reportTypes.find((type) => type.value === row.original.report_type)

      return (
        <div className="flex space-x-2">
          <Badge variant="outline">{label?.label || 'Makesafe Report'}</Badge>
        </div>
      )
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => {
      const status = statuses.find(
        (status) => status.value === row.getValue("status")
      )

      if (!status) {
        return null
      }

      return (
        <div className="flex w-[100px] items-center">
          {status.label === "Draft" ? (
            <Badge variant="default" className="bg-amber-500">{status.label}</Badge>
          ) : (
            <Badge variant="default" className="bg-emerald-600">{status.label}</Badge>
          )}
        </div>
      )
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  // {
  //   id: "actions",
  //   cell: ({ row }) => <DataTableRowActions row={row} />,
  // },
]
import { Icons } from "@/components/icons";
import { Button } from "@/components/ui/button";
import { useFormWizard } from "@/screens/reports/form";
import { ArrowLeft, ArrowRight, Check } from "lucide-react";

export const FormActions = ({
    goNext,
    saveAndBack,
    isFinal
}: {
    goNext: null | (() => void);
    saveAndBack?: null | (() => void);
    isFinal?: boolean;
}) => {

    const { stage, loading, goPrev } = useFormWizard();

    return <div className="flex w-full justify-end">
        <div className="flex space-x-2">
            <Button 
                type="button"
                variant="secondary" 
                onClick={goPrev} 
                disabled={loading}
                className="disabled:cursor-not-allowed"
            >
                {loading ? (
                    <Icons.spinner className="animate-spin mr-2 w-4 h-4" />
                ) : (
                    <ArrowLeft className="mr-2 w-4 h-4" />
                )}
                Back
            </Button>
            {saveAndBack && (
                <Button 
                    type="button"
                    variant="secondary" 
                    onClick={saveAndBack} 
                    disabled={loading}
                    className="disabled:cursor-not-allowed"
                >
                    {loading ? (
                        <Icons.spinner className="animate-spin mr-2 w-4 h-4" />
                    ) : (
                        <ArrowLeft className="mr-2 w-4 h-4" />
                    )}
                    Save & Back
                </Button>
            )}
            <Button 
                type="button"
                variant="default" 
                onClick={!goNext ? () => {} : goNext} 
                disabled={goNext === null || loading}
                className="disabled:cursor-not-allowed"
            >
                {loading ? (
                    <Icons.spinner className="animate-spin w-4 h-4" />
                ) : (
                    <>
                        {isFinal ? (
                            <>Complete <Check className="ml-2 w-4 h-4" /></>
                        ) : (
                            <>Next <ArrowRight className="ml-2 w-4 h-4" /></>
                        )} 
                    </>
                )}
            </Button>
        </div>
    </div>
}
"use client"

import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { useFormWizard } from "@/screens/reports/form"
import { FormActions } from "@/screens/reports/form/form-actions";
import { cn } from "@/lib/utils";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "@/lib/firebase";
import { Input } from "@/components/ui/input";
import { useCallback, useEffect, useState } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { CheckMark } from "@/screens/reports/form/base-questions";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";

const formSchema = z.object({
    job_number: z
      .string().min(1, {
        message: "Please enter a job number",
      }),
    client_reference: z
      .string().optional(),
    customer_name: z
      .string().min(1, {
        message: "Please enter the customer's name",
      }),
    site_address1: z
      .string().optional(),
    site_address2: z
      .string().optional(),
    site_city: z
      .string().optional(),
    site_state: z
      .string().optional(),
    site_postcode: z
      .string().optional(),
    owner_in_attendance: z
        .string().min(1, {
            message: "Please select an option",
        }),
    weather: z
      .string().optional(),
  });
  
type FormValues = z.infer<typeof formSchema>;

export const PropertyStage = () => {

    const { goNext, setLoading, report } = useFormWizard();

    const [manual, setManual] = useState<boolean>(false);
    const [autocomplete, setAutocomplete] = useState<any>(null);

    const values = {
        job_number: report?.job_number || "",
        client_reference: report?.client_reference || "",
        customer_name: report?.customer_name || "",
        site_address1: report?.site_address1 || "",
        site_address2: report?.site_address2 || "",
        site_city: report?.site_city || "",
        site_state: report?.site_state || "",
        site_postcode: report?.site_postcode || "",
        owner_in_attendance: report?.owner_in_attendance || "",
        weather: report?.weather || "",
    }

    const defaultValues: Partial<FormValues> = values;

    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues,
        mode: "onChange",
    });

    const isLocked = !!report?.pdf;

    useEffect(() => {
        form.reset(values);
        setManual(report?.autocomplete_address ? false : true);
    }, [report]);

    const toggleManual = useCallback(() => {
        if (manual) {
            setAutocomplete(null);
        }
        setManual(!manual);
    }, [manual])

    const onSubmit = useCallback(async(data: FormValues) => {
        if (!report?.id) return;

        if (manual && !report?.site_address1) {
            alert("Please enter a full address or search for the address.");
            return;
        }

        if (!manual && (!autocomplete && !report?.autocomplete_address)) {
            alert("Please select an address or enter one manually.");
            return;
        }

        setLoading(true);

        const addressFields = report?.pdf ? {
            site_address1: report?.site_address1,
            site_address2: report?.site_address2,
            site_city: report?.site_city,
            site_postcode: report?.site_postcode,
            site_state: report?.site_state,
            autocomplete_address: report?.autocomplete_address || null,
        } : manual ? {
            autocomplete_address: null,
            site_address1: data.site_address1,
            site_address2: data.site_address2,
            site_city: data.site_city,
            site_postcode: data.site_postcode,
            site_state: data.site_state,
        } : {
            autocomplete_address: autocomplete?.value?.description || report?.autocomplete_address,
            site_address1: "",
            site_address2: "",
            site_city: "",
            site_postcode: "",
            site_state: ""
        };

        await updateDoc(doc(db, "Reports", report?.id), {
            ...data,
            ...addressFields
        });

        setLoading(false);
        goNext();
    }, [manual, report, autocomplete])

    return <Card className="w-full rounded-none">
        <CardHeader>
            <CardTitle>Client Details</CardTitle>
            <CardDescription>Some basic information about the client & property.</CardDescription>
        </CardHeader>
        <CardContent>
            <div className="flex flex-col space-y-4">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="grid grid-cols-2 gap-4">
                        <div className="col-span-1">
                            <FormField
                                control={form.control}
                                name="job_number"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Job Number</FormLabel>
                                        <FormControl>
                                            <div className="relative flex items-center">
                                                <Input type="text" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                                            </div>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="col-span-1">
                            <FormField
                                control={form.control}
                                name="client_reference"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Client Reference</FormLabel>
                                        <FormControl>
                                            <div className="relative flex items-center">
                                                <Input type="text" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                                            </div>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="col-span-1">
                            <FormField
                                control={form.control}
                                name="customer_name"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Property Representative Name</FormLabel>
                                        <FormControl>
                                            <div className="relative flex items-center">
                                                <Input type="text" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                                            </div>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <Separator className="col-span-2"/>
                        {!manual && (
                        <div className="col-span-2">
                            <FormItem>
                                <FormLabel>Site Address</FormLabel>
                                <FormControl>
                                    <div className="relative flex items-center w-full">
                                        <GooglePlacesAutocomplete 
                                            apiOptions={{
                                                region: "AUS",
                                            }}
                                            selectProps={{  
                                                value: autocomplete,
                                                onChange: (e) => setAutocomplete(e),
                                                defaultInputValue: report?.autocomplete_address,
                                                isDisabled: isLocked,
                                                styles: {
                                                    menu: (provided) => ({
                                                    ...provided,
                                                    width: '100%',
                                                    }),
                                                    container: (provided) => ({
                                                        ...provided,
                                                        width: '100%',
                                                    })
                                                },
                                            }}
                                        />
                                    </div>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        </div>
                        )}
                        {!report?.pdf && (
                        <div className="col-span-2">
                            <Button type="button" variant="link" onClick={toggleManual} className="px-0">
                                {manual ? 'Search Address' : 'Enter Manually'}
                            </Button>
                        </div>
                        )}
                        {manual && (
                            <>
                                <div className="col-span-1">
                                    <FormField
                                        control={form.control}
                                        name="site_address1"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Address Line 1</FormLabel>
                                                <FormControl>
                                                    <div className="relative flex items-center">
                                                        <Input type="text" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} disabled={isLocked} />
                                                    </div>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <FormField
                                        control={form.control}
                                        name="site_address2"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Address Line 2</FormLabel>
                                                <FormControl>
                                                    <div className="relative flex items-center">
                                                        <Input type="text" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} disabled={isLocked} />
                                                    </div>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <FormField
                                        control={form.control}
                                        name="site_city"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Town/City</FormLabel>
                                                <FormControl>
                                                    <div className="relative flex items-center">
                                                        <Input type="text" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} disabled={isLocked} />
                                                    </div>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <FormField
                                        control={form.control}
                                        name="site_state"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>State</FormLabel>
                                                <FormControl>
                                                    <div className="relative flex items-center">
                                                        <Input type="text" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} disabled={isLocked} />
                                                    </div>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <FormField
                                        control={form.control}
                                        name="site_postcode"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Post Code</FormLabel>
                                                <FormControl>
                                                    <div className="relative flex items-center">
                                                        <Input type="text" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} disabled={isLocked} />
                                                    </div>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </>
                        )}
                        <Separator className="col-span-2"/>
                        <div className="col-span-1">
                            <FormField
                                control={form.control}
                                name="owner_in_attendance"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Was the owner in attendance?</FormLabel>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <FormControl>
                                                <SelectTrigger className={cn("relative", field.value ? "border-2 border-emerald-600 pr-10" : "")}>
                                                    <SelectValue placeholder="Owner Attendance" />
                                                    <CheckMark val={field.value} />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                <SelectItem value="Yes">Yes</SelectItem>
                                                <SelectItem value="No">No</SelectItem>
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </form>
                </Form>
            </div>
        </CardContent>
        <CardFooter>
            <FormActions goNext={form.handleSubmit(onSubmit)} isFinal={false} />
        </CardFooter>
    </Card>
}
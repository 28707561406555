"use client"

import { useState, useEffect, useCallback } from "react"
import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { useAuth } from '@/providers/auth-provider';
import { QueryDocumentSnapshot, collection, limit, onSnapshot, orderBy, query, startAfter, where } from 'firebase/firestore';
import { db, storage } from "@/lib/firebase";
import { columns } from "@/screens/reports/columns";
import { ReportType } from "@/screens/reports/data/schema";
import { DataTableToolbar } from "@/screens/reports/data-table-toolbar";
import { DataTablePagination } from "@/screens/reports/data-table-pagination";
import { useSiteState } from "@/providers/state-provider";
import { Link } from "react-router-dom";
import { Button, buttonVariants } from "@/components/ui/button";
import { buildReport } from "@/lib/reports";
import { Icons } from "@/components/icons";
import { Download } from "lucide-react";
import { getDownloadURL, ref } from "firebase/storage";
import { reportUrl } from "@/lib/utils";

export function ReportsScreen() {

    const { user, userData } = useAuth();
    const { setRecentReports } = useSiteState();

    const [loading, setLoading] = useState<boolean>(false);
    const [reports, setReports] = useState<ReportType[]>([]);

    const [rowSelection, setRowSelection] = useState({})
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [sorting, setSorting] = useState<SortingState>([])
    const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot | null>(null);
    const [pagination, setPagination] = useState({
        size: 10,
        index: 0
    });

    const generate = useCallback(async(id: string) => {
        setLoading(true);
        await buildReport(id, userData);
        setLoading(false);
    }, [userData])

    const downloadReport = async(path: string) => {
        setLoading(true);
        const url = await getDownloadURL(ref(storage, path));
        window.open(url);
        setLoading(false);
    }

    const table = useReactTable({
        data: reports,
        columns: columns,
        state: {
            sorting,
            columnVisibility: {
                id: false,
                pdf: false,
                reportTemplate: false,
                autocomplete_address: false
            },
            rowSelection,
            columnFilters,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });

    const page = table.options.state.pagination;

    useEffect(() => {
        if (page?.pageIndex !== pagination.index || page?.pageSize !== pagination.size) {
            setPagination({
                size: page?.pageSize || 10,
                index: page?.pageIndex || 0
            });
        }
    }, [page])

    useEffect(() => {
        if (user?.uid) {
            let q = query(
                collection(db, "Reports"), 
                where("userId", "==", user.uid), 
                orderBy("created_date", "desc"),
                limit(pagination?.size || 10),
            );
            if (pagination?.index > 0 && lastDoc) {
                q = query(q, startAfter(lastDoc));
            }
            const unsubscribe = onSnapshot(q, (docs) => {
                const r = [] as ReportType[];
                docs.forEach(d => {
                    const data = d.data() as ReportType;
                    r.push(data);
                });
                setLastDoc(docs.docs[docs.docs.length - 1]);
                setReports(r);
                setRecentReports(r.filter((r) => !r.pdf).slice(0, 6));
            });

            return unsubscribe;
        }
    }, [user, pagination])

    return (
        <div className="flex-1 space-y-4 p-8 pt-6">
            <div className="space-y-4">
                <DataTableToolbar table={table} />
                <div className="rounded-md border">
                    <Table>
                        <TableHeader>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </TableHead>
                                    )
                                    })}
                                </TableRow>
                            ))}
                        </TableHeader>
                        <TableBody>
                            {table.getRowModel().rows?.length ? (
                                table.getRowModel().rows.map((row) => {
                                    const url = reportUrl(row.getValue("reportTemplate"), row.getValue("id"))
                                    return <TableRow
                                        key={row.id}
                                        data-state={row.getIsSelected() && "selected"}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <TableCell key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </TableCell>
                                        ))}
                                        <TableCell>
                                            <div className="flex items-center space-x-1.5">
                                                <Link to={url} className={buttonVariants({ variant: 'outline', size: 'sm'})}>
                                                    Edit
                                                </Link>
                                                {row.getValue("status") === "complete" && (
                                                    <>
                                                    {row.getValue("pdf") && (
                                                    <Button size="sm" type="button" variant="outline" disabled={loading} onClick={() => downloadReport(row.getValue("pdf"))}>
                                                        {loading ? <Icons.spinner className="animate-spin w-4 h-4"/> : <Download className="w-4" />}
                                                    </Button>
                                                    )}
                                                    {/* {!row.getValue("pdf") && ( */}
                                                    <Button size="sm" type="button" disabled={loading} onClick={() => generate(row.getValue("id"))}>
                                                        {loading ? <Icons.spinner className="animate-spin w-4 h-4"/> : "Generate"}
                                                    </Button>
                                                    {/* )} */}
                                                    </>
                                                )}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                })
                            ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    className="h-24 text-center"
                                >
                                    No results.
                                </TableCell>
                            </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <DataTablePagination table={table} />
            </div>
        </div>
    )
}
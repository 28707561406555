import { Separator } from '@/components/ui/separator';
import { SettingsForm } from '@/screens/profile/settings-form';
import React from 'react';

const SettingsScreen = () => {
    return (
        <div className="space-y-6 max-w-2xl">
            <div>
                <h3 className="text-lg font-medium">Profile Settings</h3>
                <p className="text-sm text-muted-foreground">
                    Authentication details and profile-related settings.
                </p>
            </div>
            <Separator />
            <SettingsForm />
        </div>
    );
};

export default SettingsScreen;